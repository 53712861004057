.input {
	min-width: 350px !important;
}
.cardBody > *:not(:last-child):not(:first-child) {
	margin-bottom: 3.0rem !important;
}
span.unit {
	font-size: 0.8em;
	color: #666;
}

div.disabled span.unit {
	color: #CCC;
}

.bold-header{
	font-weight: bold;
	color: var(--accent);
	font-size: 1.1em;
}
.switchStatus {
	padding-top: 2px;
	font-size: 0.85em;
	color: #666;
}
.labelSlider{
	width: 200px;
}
.labelSlider.rauheit{
	width: 350px;
}
div.disabled > *{
	color : #CCC;
}

.aspectWrapper > div > *{
	height: 30px;
}

.disabled .text-secondary {
	color: #bbb !important;
}