:root {
  --accent-dark: #03326c;
  --accent: #5c679d;
  --accent-light: #939ac0;
}

.superWrapper{
  min-width: 28rem;
  /*background: #333;*/
}
div.card-header{
  background-color: var(--accent-dark);
  color:white;
}

.card {
  margin: 20px 0px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}
@media (min-width: 1200px){
  .wrapper {
    padding: 0px 30px;
    max-width: 1600px !important;
    justify-content: center;
  }
}