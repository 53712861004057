
.fixedResult {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #eee;
  box-shadow: #0004 0px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.fixedResult .list-group-item {
  border: none;
  background-color: #eee;
  margin: 0;
}
.fixedResult > h3 {
  padding-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 5px;
}

@media (max-width: 743px){
  .fixedResult {
    flex-direction: column;
  }
  .fixedResult > h3 {
    padding-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 5px;
    width: 100%;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    background-color: var(--accent-light);
    color: #fff;
  }
}