
nav .navbar-brand a{
	color: var(--accent-dark) !important;
	font-weight: bold;
	font-size: 1.5rem;
	letter-spacing: 2px;
}

.nav2 {
	background-color: var(--accent-light);
}

nav.nav2 .navbar-text {
	color: white;
	font-size: 0.9rem;
	padding: 0;
}
