.cardWrapper {
	min-width: 350px !important;
	overflow: hidden;
}
.name {
	font-weight: bold;
	color: var(--accent);
}

.cardSubTitle.card-title{
	padding-top: 15px;
	margin-bottom: -10px;
}
.value{
	text-align: right;
	padding-left: 3px;
}
.value > span{
	color: #777;
	font-size: 0.9em;
}

.costs .list-group-item {
	display: flex;
	justify-content: center;
}
.costs span{
	min-width: 70px;
	display: inline-block;
	text-align: right;
	font-weight: bold;
	color: var(--accent-dark);
}

.card-header.advancedTitle {
	border-bottom: #AAA 1px solid;
	cursor: pointer;
}
.clickable {
	cursor: pointer;
}

@media (min-width: 576){
  .cardWrapper{
		min-width: 25rem;
	}
}