footer {
	color: #888;
}
footer a{
	color: #558;
}
footer.footer {
	padding-bottom: 150px;
}

div.footerImg {
	flex-basis: auto;
	width: 100%;
	display: flex;
	justify-content: center;
}

div.footerText {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media (min-width: 992px){
  div.footerImg {
		flex-grow: 0;
		flex-basis: 0;
	}
}