button.list-group-item.active{
	background-color: #c2dfff;
	color: #333;
}

.listName{
	font-size: 1.1em;
}
.listSubName {
	color: #888;
}
.listItem > div.row {
	height: 65px;
}

.listItem.disabled * {
	color: #CCC !important;
}
.listItem.disabled .price {
	display: none !important;
}

/*----- PRICE --------*/
.price {
	color: #444;
	font-weight: bold;
	font-size: 1.5rem;
}
.price > div{
	height: 100%;
}
.price .euro, .price .comma {
	font-size: 2.5rem;
}
.price .centUnit{
	font-size: 1.4rem;
	padding-top: 7px;
	margin-left: -4px;
}
.price .unit{
	font-size: 1.0rem;
	color: #888;
	padding-left: 7px;
  margin-top: -12px;
}